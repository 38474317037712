import {NgModule} from '@angular/core';
import {NouisliderComponent} from './ng2-nouislider.component';
export class NouisliderModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    exports: [NouisliderComponent],
    declarations: [NouisliderComponent],
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function NouisliderModule_tsickle_Closure_declarations() {
/** @type {?} */
NouisliderModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
NouisliderModule.ctorParameters;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
